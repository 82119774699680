import { FC, useEffect, useId, useState } from "react"
import { Navigate, useOutletContext } from "react-router-dom"

import type { LoggedInUser } from "../auth/useGetLoggedInUser"
import { User, listAllUsers } from "../api/users"
import type { RemoteData } from "../utils/remotedata"

import useDocumentTitle from "../hooks/useDocumentTitle"
import useAppConfig from "../hooks/useConfig"

import ErrorMessage from "../components/ErrorMessage"
import { UnauthorisedError } from "../api/utils"
import Loading from "../components/Loading"
import CreateUserForm from "./CreateUserForm"
import TableActions from "./TableActions"
import * as Sentry from "@sentry/react"
import classes from "./index.module.css"
import { useRedirectToLogin } from "../hooks/useRedirectToLogin"

const PAGE_NAME = "Manage users"

const AdminScreen: FC = () => {
  useDocumentTitle(PAGE_NAME)

  const { API_URL } = useAppConfig()
  const { accessToken, role } = useOutletContext<LoggedInUser>()

  const [usersReq, setUsersReq] = useState<RemoteData<Error, User[]>>({
    type: "NotAsked",
  })

  const redirectToLogin = useRedirectToLogin()

  useEffect(() => {
    // Don't bother to make the request if the user doesn't have permission
    if (role !== "ORG_ADMIN") {
      return
    }

    const go = async () => {
      setUsersReq({ type: "Loading" })

      try {
        const data = await listAllUsers(API_URL, accessToken)
        setUsersReq({ type: "Success", data })
      } catch (e) {
        if (e instanceof Error) {
          Sentry.captureException(e)
          if (e instanceof UnauthorisedError) {
            // Handle 401 error and navigate to login page
            redirectToLogin()
          }
          setUsersReq({ type: "Failure", error: e })
        }
      }
    }
    go()
  }, [API_URL, accessToken, role, redirectToLogin])

  const existingUsersHeadingId = useId()
  const newUserHeadingId = useId()

  if (role !== "ORG_ADMIN") {
    return <Navigate to="/" />
  }

  return (
    <div className={classes.main}>
      <h1>{PAGE_NAME}</h1>

      {usersReq.type === "Success" ? (
        <>
          <section aria-labelledby={existingUsersHeadingId}>
            <h2 id={existingUsersHeadingId}>Existing users</h2>

            <table aria-labelledby={existingUsersHeadingId}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {usersReq.data.map((user) => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>
                      <TableActions
                        user={user}
                        onDelete={() => {
                          setUsersReq({
                            type: "Success",
                            data: usersReq.data.filter((u) => u.id !== user.id),
                          })
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
          <section aria-labelledby={newUserHeadingId}>
            <h2 id={newUserHeadingId}>Add new user</h2>

            <CreateUserForm
              aria-labelledby={newUserHeadingId}
              onCreate={(newUser) => {
                setUsersReq({
                  type: "Success",
                  data: [...usersReq.data, newUser],
                })
              }}
            />
          </section>
        </>
      ) : usersReq.type === "Failure" ? (
        <ErrorMessage
          message="Failed to load users"
          error={usersReq.error}
          dataTestId={"fetch-users-error"}
        />
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default AdminScreen
