import { FC } from "react"
import { Outlet, Navigate } from "react-router-dom"

import Loading from "../components/Loading"

import useGetLoggedInUser from "./useGetLoggedInUser"

const AuthRoute: FC = () => {
  const userReq = useGetLoggedInUser()

  switch (userReq.type) {
    case "NotAsked":
    case "Loading":
      return <Loading />
    case "Success":
      return <Outlet context={userReq.data} />
    case "Failure":
      return <Navigate to="/auth/login" />
  }
}

export default AuthRoute
