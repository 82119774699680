import React, { useState } from "react"
import styles from "./SearchBar.module.css"
import { validateUmr } from "../utils/umrValidator"

const SearchBar: React.FC<{
  onSearch: (umr: string) => void
  onReset: () => void
}> = ({ onSearch, onReset }) => {
  const [umr, setUmr] = useState("")
  const [isValid, setIsValid] = useState(true)

  const handleSearch = () => {
    if (validateUmr(umr)) {
      onSearch(umr)
    }
  }

  const handleReset = () => {
    setUmr("")
    setIsValid(true)
    onReset()
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setUmr(value)
    const isValid = validateUmr(value)
    setIsValid(isValid)
  }

  return (
    <div className={styles.filterContainer}>
      <label htmlFor="umrSearch" className={styles.filterLabel}>
        Filter by UMR:
      </label>
      <div className={styles.inputWrapper}>
        <input
          type="text"
          id="umrSearch"
          className={`${styles.searchInput} ${!isValid && styles.invalidInput}`}
          value={umr}
          onChange={handleInputChange}
          placeholder="Enter UMR"
        />
        <p className={`${styles.errorText} ${!isValid ? styles.visible : ""}`}>
          Special characters are not supported. Please use numbers 0-9 and
          letters A-Z.
        </p>
      </div>
      <button
        className={styles.searchButton}
        onClick={handleSearch}
        disabled={!umr.trim() || !isValid}
        title="Please enter a valid alphanumeric UMR before searching."
      >
        Search
      </button>
      <button
        className={styles.resetButton}
        onClick={handleReset}
        title="Reset the UMR filter and view all documents."
      >
        Reset
      </button>
    </div>
  )
}

export default SearchBar
