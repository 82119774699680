import { FC } from "react"
import { useNavigate } from "react-router-dom"

import Button from "./components/Button"

const NotFoundScreen: FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <h1>Page not found</h1>
      <Button label="Go home" onClick={() => navigate("/")} />
    </>
  )
}

export default NotFoundScreen
