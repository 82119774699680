import { useNavigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { useCallback } from "react"

export const useRedirectToLogin = () => {
  const navigate = useNavigate()
  const { logout } = useAuth0()
  const redirectToLogin = useCallback(() => {
    navigate("/auth/login")
    logout({ logoutParams: { returnTo: window.location.origin } })
  }, [navigate, logout])

  return redirectToLogin
}

export default useRedirectToLogin
