import { useEffect, useState } from "react"
import * as Sentry from "@sentry/react"

import type { RemoteData } from "../utils/remotedata"

const useFetch = <T>(
  fetcher: (baseUrl: string, token: string, extraParam?: string) => Promise<T>,
  baseUrl: string,
  token: string,
  extraParam?: string,
): RemoteData<Error, T> => {
  const [req, setReq] = useState<RemoteData<Error, T>>({
    type: "NotAsked",
  })

  useEffect(() => {
    const go = async () => {
      setReq({ type: "Loading" })

      try {
        const data =
          extraParam !== undefined
            ? await fetcher(baseUrl, token, extraParam)
            : await fetcher(baseUrl, token)
        setReq({ type: "Success", data })
      } catch (e) {
        if (e instanceof Error) {
          Sentry.captureException(e)
          setReq({ type: "Failure", error: e })
        }
      }
    }

    go().catch((error) => {
      Sentry.captureException(error)
      console.error("Failed to fetch documents:", error)
    })
  }, [fetcher, baseUrl, token, extraParam])

  return req
}

export default useFetch
