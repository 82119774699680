import { FC } from "react"
import { Navigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

import Button from "../components/Button"

const CallbackScreen: FC = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  if (isAuthenticated) {
    return <Navigate to="/" />
  }

  return (
    <div>
      <h1>Ki partner portal</h1>

      <Button label="Log in" onClick={() => loginWithRedirect()} />
    </div>
  )
}

export default CallbackScreen
