import { FC, ReactNode } from "react"

import { SpinnerSVG } from "./Loading"

import classes from "./Button.module.css"

export type State = "active" | "loading" | "disabled"

export type Size = "medium" | "small"

export type Prominence = "primary" | "secondary" | "tertiary"

export type Icon =
  | { position: "leading"; icon: ReactNode }
  | { position: "trailing"; icon: ReactNode }
  | { position: "icon-only"; icon: ReactNode }

const Button: FC<{
  icon?: Icon
  onClick?: () => void
  prominence?: Prominence
  size?: Size
  state?: State
  label: string
  "aria-label"?: string
  type?: HTMLButtonElement["type"]
}> = ({
  icon,
  onClick,
  prominence = "primary",
  size = "medium",
  state = "active",
  label,
  "aria-label": ariaLabel,
  type,
}) => {
  let contents: ReactNode
  if (state === "loading") {
    contents = <SpinnerSVG />
  } else if (icon?.position === "icon-only") {
    contents = (
      <>
        <span className={classes.icon}>{icon.icon}</span>
        <span className="sr-only">{label}</span>
      </>
    )
  } else if (icon?.position === "leading") {
    contents = (
      <>
        <span className={classes.icon}>{icon.icon}</span>
        <span>{label}</span>
      </>
    )
  } else if (icon?.position === "trailing") {
    contents = (
      <>
        <span>{label}</span>
        <span className={classes.icon}>{icon.icon}</span>
      </>
    )
  } else {
    contents = <span>{label}</span>
  }

  const title: string | undefined =
    state === "loading"
      ? "Loading"
      : icon?.position === "icon-only"
      ? label
      : undefined

  return (
    <button
      data-prominence={prominence}
      data-size={size}
      data-state={state}
      className={classes.button}
      disabled={state === "disabled" || state === "loading"}
      type={type}
      onClick={onClick}
      aria-label={ariaLabel ?? title}
      title={title}
    >
      {contents}
    </button>
  )
}

export default Button
