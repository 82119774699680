import { mkHeaders, UnauthorisedError } from "./utils"

export interface Endorsement {
  name: string
  children: Endorsement[] | null
  isZip?: boolean
}

export const listEndorsements = async (
  baseUrl: string,
  token: string,
): Promise<Endorsement> => {
  const res = await fetch(`${baseUrl}/api/endorsements`, {
    headers: mkHeaders(token),
  })

  if (res.status === 401) {
    throw new UnauthorisedError(
      `Failed Unathorised Access: response ${res.status}`,
    )
  }

  if (res.status !== 200) {
    const msg = await res.text()
    throw new Error(`Failed to list documents: response ${res.status} ${msg}`)
  }

  const docs: Endorsement = await res.json()
  return docs
}

export const listEndorsementsByUMR: {
  (baseUrl: string, token: string, umr: string): Promise<Endorsement>
  (baseUrl: string, token: string): Promise<Endorsement>
} = async (
  baseUrl: string,
  token: string,
  umr?: string,
): Promise<Endorsement> => {
  const url = umr
    ? `${baseUrl}/api/endorsements/${encodeURIComponent(umr)}`
    : `${baseUrl}/api/endorsements`

  const res = await fetch(url, {
    headers: mkHeaders(token),
  })

  if (res.status === 401) {
    throw new UnauthorisedError(
      `Failed Unauthorised Access: response ${res.status}`,
    )
  }

  if (res.status !== 200) {
    const msg = await res.text()
    throw new Error(`Failed to list documents: response ${res.status} ${msg}`)
  }

  return await res.json()
}

export interface SignedUrl {
  url: string
  contentType: string
}

export const getEndorsementPresignedUrl = async (
  baseUrl: string,
  token: string,
  fileName: string,
): Promise<SignedUrl> => {
  const res = await fetch(
    `${baseUrl}/api/endorsements/url?fileName=${encodeURIComponent(fileName)}`,
    {
      headers: mkHeaders(token),
    },
  )

  if (res.status === 401) {
    throw new UnauthorisedError(
      `Failed Unathorised Access: response ${res.status}`,
    )
  }

  if (res.status !== 200) {
    const msg = await res.text()
    throw new Error(
      `Failed to get document presigned URL: response ${res.status} ${msg}`,
    )
  }

  const signedUrl: SignedUrl = await res.json()
  return signedUrl
}
