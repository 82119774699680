import { FC, useId, useState } from "react"
import { useOutletContext } from "react-router-dom"

import useAppConfig from "../hooks/useConfig"
import { LoggedInUser } from "../auth/useGetLoggedInUser"

import { User, createUser, Role } from "../api/users"
import type { RemoteData } from "../utils/remotedata"

import Button from "../components/Button"
import ErrorMessage from "../components/ErrorMessage"
import { UnauthorisedError } from "../api/utils"

import classes from "./CreateUserForm.module.css"
import * as Sentry from "@sentry/react"
import useRedirectToLogin from "../hooks/useRedirectToLogin"

const defaultName: string = ""
const defaultEmail: string = ""
const defaultRole: Role = "USER"

const CreateUserForm: FC<{
  "aria-labelledby": string
  onCreate: (newUser: User) => void
}> = ({ "aria-labelledby": ariaLabelledBy, onCreate }) => {
  const { API_URL } = useAppConfig()
  const loggedInUser = useOutletContext<LoggedInUser>()

  const [name, setName] = useState<string>(defaultName)
  const [email, setEmail] = useState<string>(defaultEmail)
  const [role, setRole] = useState<Role>(defaultRole)

  const [createReq, setCreateReq] = useState<RemoteData<Error, User>>({
    type: "NotAsked",
  })

  const roleGroupName = useId()
  const redirectToLogin = useRedirectToLogin()

  return (
    <form
      className={classes.form}
      aria-labelledby={ariaLabelledBy}
      onSubmit={async (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (!name || !email) {
          // TODO error?
          return
        }

        try {
          setCreateReq({ type: "Loading" })
          const user = await createUser(
            { email, name, role },
            API_URL,
            loggedInUser.accessToken,
          )
          setCreateReq({ type: "Success", data: user })

          setName(defaultName)
          setEmail(defaultEmail)
          setRole(defaultRole)

          onCreate(user)
        } catch (e) {
          if (e instanceof Error) {
            Sentry.captureException(e)
            if (e instanceof UnauthorisedError) {
              // Handle 401 error and navigate to login page
              redirectToLogin()
            }
            setCreateReq({ type: "Failure", error: e })
          }
        }
      }}
    >
      <label className={classes.input}>
        <span>Name</span>
        <input
          required
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </label>

      <label className={classes.input}>
        <span>Email</span>
        <input
          required
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </label>

      <fieldset>
        <legend>Role</legend>

        <div className={classes.inputs}>
          <label>
            <span>User</span>
            <input
              required
              type="radio"
              name={roleGroupName}
              value={"USER" satisfies Role}
              checked={role === "USER"}
              onChange={(e) => setRole(e.target.value as Role)}
            />
          </label>

          <label>
            <span>Admin</span>
            <input
              required
              type="radio"
              name={roleGroupName}
              value={"ORG_ADMIN" satisfies Role}
              checked={role === "ORG_ADMIN"}
              onChange={(e) => setRole(e.target.value as Role)}
            />
          </label>
        </div>
      </fieldset>

      <Button
        type="submit"
        label="Submit"
        state={createReq.type === "Loading" ? "loading" : "active"}
      />

      <div aria-live="polite">
        {createReq.type === "Failure" && (
          <ErrorMessage
            dataTestId="create-user-error"
            message="Failed to create user"
            error={createReq.error}
          />
        )}
      </div>
    </form>
  )
}

export default CreateUserForm
