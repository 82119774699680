import { mkHeaders, PaginatedResponse } from "./utils"
import { UnauthorisedError } from "./utils"

export type Role = "ORG_ADMIN" | "USER"

export interface User {
  email: string
  id: string
  name: string
  role: Role
}

// TODO deal with pagination
export const listAllUsers = async (
  baseUrl: string,
  token: string,
): Promise<User[]> => {
  const res = await fetch(`${baseUrl}/api/users`, {
    headers: mkHeaders(token),
  })

  if (res.status === 401) {
    throw new UnauthorisedError(
      `Failed Unathorised Access: response ${res.status}`,
    )
  }

  if (res.status !== 200) {
    const msg = await res.text()
    throw new Error(`Failed to list users: response ${res.status} ${msg}`)
  }

  const page: PaginatedResponse<User> = await res.json()
  return page.content
}

export type PartialUser = Omit<User, "id">

export const createUser = async (
  partial: PartialUser,
  baseUrl: string,
  token: string,
): Promise<User> => {
  const res = await fetch(`${baseUrl}/api/users`, {
    method: "POST",
    headers: mkHeaders(token),
    body: JSON.stringify(partial),
  })

  if (res.status === 401) {
    throw new UnauthorisedError(
      `Failed Unathorised Access: response ${res.status}`,
    )
  }

  if (res.status !== 201) {
    const msg = await res.text()
    throw new Error(`Failed to create user: response ${res.status} ${msg}`)
  }

  const user: User = await res.json()
  return user
}

export const deleteUser = async (
  userId: User["id"],
  baseUrl: string,
  token: string,
): Promise<void> => {
  const res = await fetch(`${baseUrl}/api/users/${userId}`, {
    headers: mkHeaders(token),
    method: "DELETE",
  })

  if (res.status === 401) {
    throw new UnauthorisedError(
      `Failed Unathorised Access: response ${res.status}`,
    )
  }

  if (res.status !== 200) {
    const msg = await res.text()
    throw new Error(`Failed to delete user: response ${res.status} ${msg}`)
  }
}
