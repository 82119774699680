import { FC } from "react"
import { Navigate } from "react-router-dom"

import ErrorMessage from "../components/ErrorMessage"
import Loading from "../components/Loading"

import useGetLoggedInUser from "./useGetLoggedInUser"

const CallbackScreen: FC = () => {
  const userReq = useGetLoggedInUser()

  switch (userReq.type) {
    case "NotAsked":
    case "Loading":
      return <Loading />
    case "Success":
      return <Navigate to="/" />
    case "Failure":
      return <ErrorMessage message="Failed to log in" error={userReq.error} />
  }
}

export default CallbackScreen
