import { FC } from "react"
import { NavLink } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

import useGetLoggedInUser from "../auth/useGetLoggedInUser"

import KiLogo from "./KiLogo"
import Button from "../components/Button"

import classNames from "classnames"
import classes from "./Nav.module.css"

export const Header: FC = () => {
  const userReq = useGetLoggedInUser()
  const { logout } = useAuth0()

  return (
    <header className={classNames(classes.banner, classes.header)}>
      <nav aria-label="Site">
        <a href="/" className={classes.logo}>
          <KiLogo />
        </a>

        {userReq.type === "Success" && (
          <>
            <div className={classes.links}>
              <NavLink to="documents">Documents</NavLink>

              <NavLink to="endorsements">Endorsements</NavLink>

              {userReq.data.role === "ORG_ADMIN" && (
                <NavLink to="admin">Manage users</NavLink>
              )}
            </div>

            <div className={classes.user}>
              <span className={classes.username}>{userReq.data.name}</span>
              <Button
                prominence="secondary"
                size="small"
                label="Log out"
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
              />
            </div>
          </>
        )}
      </nav>
    </header>
  )
}

export const Footer: FC = () => (
  <footer className={classNames(classes.banner, classes.footer)}>
    <nav aria-label="Ki Insurance">
      <a href="https://ki-insurance.com" className={classes.logo}>
        <KiLogo />
      </a>

      <div className={classes.links}>
        <a href="https://ki-insurance.com/policies/Ki_Website_Terms.pdf">
          Terms and Conditions
        </a>
        <a href="https://ki-insurance.com/policies/Ki_Website_Privacy_Policy.pdf">
          Privacy and Cookies Policy
        </a>
        <a href="https://ki-insurance.com/manage-cookies">Manage Cookies</a>
      </div>
    </nav>
  </footer>
)
