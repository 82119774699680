import { FC, useId, useState } from "react"
import { useOutletContext } from "react-router-dom"

import useAppConfig from "../hooks/useConfig"
import { LoggedInUser } from "../auth/useGetLoggedInUser"

import { User, deleteUser } from "../api/users"
import type { RemoteData } from "../utils/remotedata"

import Button from "../components/Button"
import ErrorMessage from "../components/ErrorMessage"
import { UnauthorisedError } from "../api/utils"

import * as Sentry from "@sentry/react"
import classes from "./TableActions.module.css"
import Modal from "react-modal"
import useRedirectToLogin from "../hooks/useRedirectToLogin"

const TableActions: FC<{ user: User; onDelete: () => void }> = ({
  user,
  onDelete,
}) => {
  const { API_URL } = useAppConfig()
  const loggedInUser = useOutletContext<LoggedInUser>()

  const [deleteReq, setDeleteReq] = useState<RemoteData<Error, null>>({
    type: "NotAsked",
  })
  const [openModal, setOpenModal] = useState(false)
  const modalHeadingId = useId()

  const redirectToLogin = useRedirectToLogin()

  return (
    <div className={classes.actions}>
      <Button
        aria-label={`Delete user ${user.name}`}
        label="Delete user"
        size="small"
        state={deleteReq.type === "Loading" ? "loading" : "active"}
        onClick={() => {
          setOpenModal(true)
        }}
      />

      <div aria-live="polite">
        {deleteReq.type === "Failure" && (
          <ErrorMessage
            message="Failed to delete user"
            error={deleteReq.error}
          />
        )}
      </div>
      <Modal
        className={classes.confirmationModal}
        isOpen={openModal}
        onRequestClose={() => setOpenModal(!openModal)}
        aria={{
          labelledby: modalHeadingId,
        }}
      >
        <h2
          id={modalHeadingId}
          className={`${classes.confirmationModalHeader} h3`}
        >
          Are you sure you want to delete {user.name}?
        </h2>
        <div className={classes.buttonContainer}>
          <Button
            label="Confirm"
            size="medium"
            state={"active"}
            type="button"
            onClick={async () => {
              try {
                setDeleteReq({ type: "Loading" })
                await deleteUser(user.id, API_URL, loggedInUser.accessToken)
                setDeleteReq({ type: "Success", data: null })
                onDelete()
              } catch (e) {
                if (e instanceof Error) {
                  Sentry.captureException(e)
                  if (e instanceof UnauthorisedError) {
                    // Handle 401 error and navigate to login page
                    redirectToLogin()
                  }
                  setDeleteReq({ type: "Failure", error: e })
                }
              }
            }}
          />
          <Button
            prominence="secondary"
            label="Cancel"
            size="medium"
            state={"active"}
            type="button"
            onClick={() => {
              setOpenModal(false)
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default TableActions
