import React from "react"
import ReactDOM from "react-dom/client"
import { Auth0Provider } from "@auth0/auth0-react"

import { Navigate, createBrowserRouter, RouterProvider } from "react-router-dom"

import App from "./App"
import { AppConfig, AppConfigContext } from "./hooks/useConfig"

import AuthRoute from "./auth/AuthRoute"
import CallbackScreen from "./auth/CallbackScreen"
import LoginScreen from "./auth/LoginScreen"
import NotFoundScreen from "./NotFoundScreen"
import DocumentsScreen from "./DocumentsScreen/index"
import AdminScreen from "./AdminScreen/index"

import ErrorMessage from "./components/ErrorMessage"

import "./design-system.module.css"
import "./index.module.css"

import * as Sentry from "@sentry/react"
import EndorsementsScreen from "./EndorsementsScreen"

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/auth/callback",
        element: <CallbackScreen />,
      },
      {
        path: "/auth/login",
        element: <LoginScreen />,
      },
      {
        element: <AuthRoute />,
        children: [
          {
            path: "documents",
            element: <DocumentsScreen />,
          },
          {
            path: "endorsements",
            element: <EndorsementsScreen />,
          },
          {
            path: "admin",
            element: <AdminScreen />,
          },
        ],
      },
      {
        path: "404",
        element: <NotFoundScreen />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
])

const root = document.getElementById("root")!

// The `config.json` file will differ at runtime depending on the environment
fetch("/config.json")
  .then((r) => r.json())
  .then((config: AppConfig) => {
    Sentry.init({
      enabled:
        config.SENTRY_ENVIRONMENT === "PRODUCTION" ||
        config.SENTRY_ENVIRONMENT === "STAGING",
      dsn: config.SENTRY_DSN,
      environment: config.SENTRY_ENVIRONMENT,
      integrations: [new Sentry.Replay()],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
    ReactDOM.createRoot(root).render(
      <React.StrictMode>
        <Sentry.ErrorBoundary>
          <AppConfigContext.Provider value={config}>
            <Auth0Provider
              domain={config.AUTH0_DOMAIN}
              clientId={config.AUTH0_CLIENT_ID}
              authorizationParams={{
                audience: config.AUTH0_AUDIENCE,
                connection: "syndicate-users",
                redirect_uri: `${window.location.origin}/auth/callback`,
              }}
            >
              <RouterProvider router={router} />
            </Auth0Provider>
          </AppConfigContext.Provider>
        </Sentry.ErrorBoundary>
      </React.StrictMode>,
    )
  })
  .catch((e) => {
    Sentry.captureException(e)
    ReactDOM.createRoot(root).render(
      <React.StrictMode>
        <ErrorMessage
          error={e instanceof Error ? e : undefined}
          message="Failed to load app config"
        />
      </React.StrictMode>,
    )
  })
