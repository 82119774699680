import { mkHeaders, UnauthorisedError } from "./utils"
import JSZip from "jszip"

export interface Document {
  name: string
  children: Document[] | null
  isZip?: boolean
  isDownloadable?: boolean
}

export const listDocuments = async (
  baseUrl: string,
  token: string,
): Promise<Document> => {
  const res = await fetch(`${baseUrl}/api/documents`, {
    headers: mkHeaders(token),
  })

  if (res.status === 401) {
    throw new UnauthorisedError(
      `Failed Unathorised Access: response ${res.status}`,
    )
  }

  if (res.status !== 200) {
    const msg = await res.text()
    throw new Error(`Failed to list documents: response ${res.status} ${msg}`)
  }

  const docs: Document = await res.json()
  return docs
}

export const listDocumentsByUMR: {
  (baseUrl: string, token: string, umr: string): Promise<Document>
  (baseUrl: string, token: string): Promise<Document>
} = async (baseUrl: string, token: string, umr?: string): Promise<Document> => {
  const url = umr
    ? `${baseUrl}/api/documents/${encodeURIComponent(umr)}`
    : `${baseUrl}/api/documents`

  const res = await fetch(url, {
    headers: mkHeaders(token),
  })

  if (res.status === 401) {
    throw new UnauthorisedError(
      `Failed Unauthorised Access: response ${res.status}`,
    )
  }

  if (res.status !== 200) {
    const msg = await res.text()
    throw new Error(`Failed to list documents: response ${res.status} ${msg}`)
  }

  return await res.json()
}

export interface SignedUrl {
  url: string
  contentType: string
}

export const getDocumentPresignedUrl = async (
  baseUrl: string,
  token: string,
  fileName: string,
): Promise<SignedUrl> => {
  const res = await fetch(
    `${baseUrl}/api/documents/url?fileName=${encodeURIComponent(fileName)}`,
    {
      headers: mkHeaders(token),
    },
  )

  if (res.status === 401) {
    throw new UnauthorisedError(
      `Failed Unathorised Access: response ${res.status}`,
    )
  }

  if (res.status !== 200) {
    const msg = await res.text()
    throw new Error(
      `Failed to get document presigned URL: response ${res.status} ${msg}`,
    )
  }

  const signedUrl: SignedUrl = await res.json()
  return signedUrl
}

export const getZipPresignedUrl = async (
  baseUrl: string,
  token: string,
  fileName: string,
): Promise<SignedUrl> => {
  const res = await fetch(
    `${baseUrl}/api/zips/url?fileName=${encodeURIComponent(fileName)}`,
    {
      headers: mkHeaders(token),
    },
  )

  if (res.status === 401) {
    throw new UnauthorisedError(
      `Failed Unathorised Access: response ${res.status}`,
    )
  }

  if (res.status !== 200) {
    const msg = await res.text()
    throw new Error(
      `Failed to get zip presigned URL: response ${res.status} ${msg}`,
    )
  }

  const signedUrl: SignedUrl = await res.json()
  return signedUrl
}

export const getFolderSignedUrls = async (
  baseUrl: string,
  token: string,
  folderName: string,
): Promise<SignedUrl[]> => {
  const response = await fetch(
    `${baseUrl}/api/documents/folder-urls?folderPrefix=${encodeURIComponent(
      folderName,
    )}`,
    {
      headers: mkHeaders(token),
    },
  )

  if (response.status === 401) {
    throw new UnauthorisedError(
      `Failed Unauthorised Access: response ${response.status}`,
    )
  }

  if (!response.ok) {
    const msg = await response.text()
    throw new Error(`Error fetching folder URLs: ${response.statusText} ${msg}`)
  }

  return await response.json()
}

export const downloadAndZipFiles = async (
  signedUrls: SignedUrl[],
  folderName: string,
) => {
  const zip = new JSZip()

  for (const file of signedUrls) {
    if (!file.url) {
      throw new Error("URL is missing for a file")
    }

    const response = await fetch(file.url)
    const blob = await response.blob()

    const parts = file.url.split("/")
    if (parts.length === 0) {
      throw new Error("Invalid URL format")
    }

    const fileName = parts.pop()?.split("?")[0] || "unknown-file"
    zip.file(fileName, blob)
  }

  const zipBlob = await zip.generateAsync({ type: "blob" })

  const link = document.createElement("a")
  link.href = URL.createObjectURL(zipBlob)
  link.download = `${folderName}.zip`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
