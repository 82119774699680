import { FC } from "react"
import { useMatches, Outlet, Navigate } from "react-router-dom"

import { Header, Footer } from "./components/Nav"

import classes from "./App.module.css"
import Modal from "react-modal"

Modal.setAppElement("#root")

const App: FC = () => {
  const matches = useMatches()

  // Redirect from `/` to `/documents`
  if (matches.length === 1 && matches[0].pathname === "/") {
    return <Navigate to="documents" replace />
  }

  return (
    <div className={classes.wrapper}>
      <Header />

      <main className={classes.main}>
        <div className={classes.inner}>
          <Outlet />
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default App
