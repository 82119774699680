import { FC } from "react"

import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"

import classes from "./ErrorMessage.module.css"

const ErrorMessage: FC<{
  error?: Error
  message: string
  dataTestId?: string
}> = ({ error, message, dataTestId }) => (
  <div role="alert" className={classes.alert} data-testid={dataTestId}>
    <ExclamationTriangleIcon className={classes.icon} />

    <div className={classes.inner}>
      <p className={classes.message}>{message}</p>
      {error && <code>{error.message}</code>}
    </div>
  </div>
)

export default ErrorMessage
