export interface PaginatedResponse<T> {
  content: T[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  size: number
  sort: {
    sorted: boolean
    unsorted: boolean
    empty: boolean
  }
  totalElements: number
  totalPages: number
}

export const mkHeaders = (token: string) => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
})

export class UnauthorisedError extends Error {
  constructor(message: string) {
    super(message)
  }
}
