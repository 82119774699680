import { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"

import type { Role } from "../api/users"
import { RemoteData } from "../utils/remotedata"

interface Auth0User {
  email?: string
  email_verified?: boolean
  name?: string
  nickname?: string
  picture?: string
  updated_at?: string
}

export interface LoggedInUser extends Auth0User {
  accessToken: string
  role: Role
  syndicate: string
}

const useGetClaims = (): RemoteData<Error, LoggedInUser> => {
  const { getIdTokenClaims, getAccessTokenSilently, isAuthenticated, user } =
    useAuth0<LoggedInUser>()

  const [claimsReq, setClaimsReq] = useState<RemoteData<Error, LoggedInUser>>({
    type: "NotAsked",
  })

  useEffect(() => {
    const go = async (): Promise<void> => {
      if (!isAuthenticated) {
        return
      }

      setClaimsReq({ type: "Loading" })

      try {
        const accessToken = await getAccessTokenSilently()
        const idToken = await getIdTokenClaims()

        if (!idToken) {
          setClaimsReq({ type: "Failure", error: new Error("No ID token") })
          return
        }

        const role = idToken[
          "https://partners.ki-insurance.com/role"
        ] as unknown
        if (role !== "ORG_ADMIN" && role !== "USER") {
          setClaimsReq({
            type: "Failure",
            error: new Error(`Invalid role: ${role}`),
          })
          return
        }

        const syndicate = idToken[
          "https://partners.ki-insurance.com/syndicate"
        ] as unknown
        if (typeof syndicate !== "string") {
          setClaimsReq({
            type: "Failure",
            error: new Error(`Invalid syndicate: ${syndicate}`),
          })
          return
        }

        setClaimsReq({
          type: "Success",
          data: { ...user, role, syndicate, accessToken },
        })
      } catch (e) {
        if (e instanceof Error) {
          setClaimsReq({ type: "Failure", error: e })
        }
      }
    }
    go()
  }, [isAuthenticated, getIdTokenClaims, getAccessTokenSilently, user])

  return claimsReq
}

const useGetLoggedInUser = (): RemoteData<Error | undefined, LoggedInUser> => {
  const { isAuthenticated, isLoading, error } = useAuth0<LoggedInUser>()

  const userWithClaimsReq = useGetClaims()

  if (isLoading) {
    return { type: "Loading" }
  } else if (isAuthenticated) {
    return userWithClaimsReq
  } else {
    return { type: "Failure", error }
  }
}

export default useGetLoggedInUser
