import { createContext, useContext } from "react"

// This is only imported for the type
import devConfig from "../../public/config.json"

export type AppConfig = typeof devConfig

export const AppConfigContext = createContext<AppConfig | null>(null)

const useAppConfig = (): AppConfig => {
  const config = useContext(AppConfigContext)
  if (!config) {
    throw new Error("Tried to access uninitialised app config")
  }

  return config
}

export default useAppConfig
